import React from 'react';
import {Col, Row} from "reactstrap";

import butterfly from "../../static/images/butterfly-blue.png";
import butterflyGold from "../../static/images/ccc-logo-transparent.png";

const ButterflyBulletPointRow = props => {
  return (
    <Row className="py-2">
      <Col xs={2} className="text-end">
        <img
          src={props.gold ? butterflyGold : butterfly}
          style={{maxHeight: '24px'}}
          alt="Cotswold Coaching Clinic logo"
        />
      </Col>
      <Col xs={10}>
        {props.label}
      </Col>
    </Row>
  )
}

export default ButterflyBulletPointRow;