import React from 'react';
import {Container, Col, Button, Row,} from "reactstrap";

import '../../onboarding.css';
import { colours } from "../../config";
import sally from "../../static/images/sally-fzen.jpg";
import reviveAndThriveImage from "../../static/images/revive-package-instagram.png"
import ButterflyBulletPointRow from "../shared/ButterflyBulletPointRow";
import logo_stacked from "../../static/images/logo-stacked.png";
import certificate from "../../static/images/gift-certificate-example.png";
import tcaLogo from "../../static/images/tca_accreditedby.jpg";
import affiliateLogo from "../../static/images/affiliate-ioc-logo.png";
import Quote from "../shared/Quote";

const GroupCoaching = () => {

  return (
    <React.Fragment>
      <Container
        fluid
        style={{minHeight: "100%"}}
      >

        <Row>

        <Col xs={12} className="d-flex justify-content-center">
          <img src={logo_stacked} style={{width: '175px', marginTop: '20px'}} alt="Cotswold Coaching Clinic Logo" />
        </Col>

        <Col xs={12} className="my-4 d-flex justify-content-center">
          <div>
            <h1 className="w-100 text-center my-4" style={{color: colours.prussianBlue, fontSize:'3em'}}>
              Looking for a unique Christmas gift for an amazing woman?
            </h1>
          </div>
        </Col>

        <Col xs={12} className="d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              Gift the power of transformation!
            </h2>
          </div>
        </Col>

        <Col xs={12} className="my-4 p-3 d-flex justify-content-center align-content-center">
          <div style={{maxWidth:'600px', height:'100%'}}>
            <p>
              It's that wonderful time of the year when we're considering what our friends and loved ones might want for Christmas.
            </p>
            <p>
              Increasingly people are opting for experiences rather than 'things' - this is great for the environment and good for the soul.
            </p>
            <p>
              If you're after something personalised, holistic and impactful, maybe for a partner, daughter, sister, mother or best friend, have a look at Cotswold Coaching Clinic's 'Revive' coaching package which is on offer until Christmas Eve.
              It's a refreshing way to kick off 2025 and you might even decide you want one for yourself!
            </p>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'500px'}}>
            <img
              src={reviveAndThriveImage}
              style={{
                width: '100%',
                marginTop: '20px',
                maxHeight: '450px',
                filter: 'drop-shadow(0 0 20px #f7d490)'
              }}
              alt="Revive and Thrive Package"
            />
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'800px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              What does the <em>Revive</em> coaching package include?
            </h2>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <div style={{maxWidth:'600px'}}>
              <ButterflyBulletPointRow label="Coaching agreement" />
              <ButterflyBulletPointRow label="Pre-coaching online questionnaire" />
              <ButterflyBulletPointRow label="A single deep dive 90-minute virtual coaching session with Dr Sally Hanna via Zoom" />
              <ButterflyBulletPointRow label="Detailed typed summary of the session to help process new knowledge and self-awareness" />
              <ButterflyBulletPointRow label="Time-limited option to  upgrade to the 'Revive and Thrive' package (total of 5 x 90-minute sessions over 3-4 months with email support between sessions)" />
            </div>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'800px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              Who is the <em>Revive</em> coaching package for?
            </h2>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
           <p>
             This package would benefit anyone who feels stuck or overwhelmed in an area of their life and wants clarity and support to overcome the obstacles getting in the way of their fulfilment.
           </p>
            <p>
              Dr Sally Hanna has previously helped women who are stressed business owners, in challenging personal or professional relationships, recently divorced or widowed, juggling work and motherhood, returning to work after prolonged leave, struggling with peri-menopause or a chronic condition, new to living in the UK, looking to advance or change their career, at risk of burnout, and those wanting to live a more authentic and intentional life.
            </p>
            <p>
              Whatever the specific scenario the underlying challenges are often the same – a lack of self-awareness, confidence and wellbeing.
            </p>
          </div>
        </Col>

          <Col xs={12} className="my-4 text-center">
            <a href="https://square.link/u/c59ijloB">
              <Button>
                Buy a Revive Coaching Package Gift Voucher<br />
                <span style={{textDecoration:'line-through'}}>&pound;300</span> &pound;240
              </Button>
            </a>
          </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'800px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              About Dr Sally Hanna
            </h2>
          </div>
        </Col>

        <Col xs={12} md={6} className="my-4 p-3 d-flex justify-content-start align-items-center align-items-md-end flex-column">
          <div style={{maxWidth:'500px'}}>
            <img
              src={sally}
              style={{
                textAlign: 'right',
                maxHeight: '450px',
                filter: 'drop-shadow(0 0 10px #f7d490)'
              }}
              alt="Sally at Farncombe Zen"
            />
          </div>
          <div className="my-4" style={{maxWidth: '300px'}}>
            <Quote
              content="“A life coach is to your mind what a personal trainer is to your body.”"
              attribution="Dr Sally Hanna"
            />
            <img className="m-4" src={tcaLogo} style={{ maxWidth: '300px'}} alt="The Coaching Academy Logo" />
            <img className="m-4" src={affiliateLogo} style={{ maxWidth: '250px'}} alt="Institute of Coaching Logo" />
          </div>
        </Col>

        <Col xs={12} md={6} className="p-4 d-flex justify-content-start">
          <div style={{maxWidth:'600px'}}>
            <p>
              After 17 years as an NHS doctor, both in hospital medicine and General Practice, Dr Sally founded Cotswold Coaching Clinic as a boutique life coaching practice for women.
              She has developed a unique and focused methodology for enhancing women’s self-esteem, performance and resilience.
            </p>
            <p>
              Common topics Dr Sally addresses in coaching include:
            </p>
            <ButterflyBulletPointRow label="long-term vision" />
            <ButterflyBulletPointRow label="empowerment" />
            <ButterflyBulletPointRow label="honouring values" />
            <ButterflyBulletPointRow label="leveraging strengths" />
            <ButterflyBulletPointRow label="harnessing intuition" />
            <ButterflyBulletPointRow label="letting go of negativity" />
            <ButterflyBulletPointRow label="self-care" />
            <ButterflyBulletPointRow label="overwhelm management" />
            <ButterflyBulletPointRow label="assertive decision-making" />
            <ButterflyBulletPointRow label="navigating change" />
            <ButterflyBulletPointRow label="enhancing relationships" />
            <ButterflyBulletPointRow label="effective communication" />
            <ButterflyBulletPointRow label="overcoming imposter syndrome" />
            <ButterflyBulletPointRow label="impactful leadership" />
            <ButterflyBulletPointRow label="resolving conflict" />
            <ButterflyBulletPointRow label="unlocking creativity" />
            <ButterflyBulletPointRow label="career progression/transition" />
            <ButterflyBulletPointRow label="ending self-sabotaging behaviours such as people-pleasing, perfectionism and procrastination" />
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <p className="mt-2">
              In addition to working with private clients Dr Sally has also worked with organisations and contributed to academic research into the benefits of coaching.
            </p>
            <p>
              Her clients vary in age from their 20s to their 60s and all have found the coaching experience to be transformational.
            </p>
            <p>
              At the core of all her work is a passion for helping women lead happy and purposeful lives which reflect their true potential.
            </p>
          </div>
        </Col>


        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'800px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              What is life coaching?
            </h2>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <p>
              Life coaching is a highly personalised and transformative wellness offering which unlocks your potential.
              It helps you to better understand who you are, what you want and how to navigate the thoughts, feelings and behaviours that hold you back from achieving your ambitions.
            </p>
            <p>
              Every session leaves you feeling seen and heard, energised and empowered, and this enhances your performance and resilience in everyday life - at home, socially and in the workplace.
            </p>
            <p>
              Watch the short video below to learn more.
            </p>
          </div>
        </Col>

        <Col xs={12} className="p-2 d-flex justify-content-center">
          <div style={{
              position: 'relative',
              width: '100%',
              maxWidth: '1024px',
              aspectRatio: '16 / 9'
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/7PQkBTrKqRw"
              title="A Christmas Fireside Chat - Part 1" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              What are the benefits of life coaching?
            </h2>
          </div>
        </Col>
        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <ButterflyBulletPointRow label="Enhanced self-esteem - by gaining a deeper understanding of their own worth and capabilities, individuals embrace challenges with renewed confidence." />
            <ButterflyBulletPointRow label="Greater clarity and focus - through insightful questioning and reflective exercises, coaching shines a light on priorities and blind spots, helping to inform decision-making." />
            <ButterflyBulletPointRow label="Improved performance - by setting clear goals and developing effective strategies, performance in both personal and professional life can be enhanced." />
            <ButterflyBulletPointRow label="Increased resilience - coaching equips individuals with the tools to navigate life's inevitable ups and downs, building resilience and adaptability." />
            <ButterflyBulletPointRow label="Reduced stress and anxiety - coaching provides a supportive environment to manage stress, reduce anxiety and build a sense of calm." />
          </div>
        </Col>

        <Col xs={12} className="my-4 text-center">
          <a href="https://square.link/u/c59ijloB">
            <Button>
              Buy a Revive Coaching Package Gift Voucher<br />
              <span style={{textDecoration:'line-through'}}>&pound;300</span> &pound;240
            </Button>
          </a>
        </Col>


        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              What do others say?
            </h2>
          </div>
        </Col>

        <Col xs={12} className="p-2 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <Quote
              medium
              content=" “I truly believe that my coaching success is down to Sally's ability to effortlessly tease things out. I now understand myself better and I know what I want from life. As a result I plan more, take more control and have a better work-life balance whilst continuing to progress my career. Sally's wealth of knowledge, intuition and friendly persona is what makes her coaching so impactful.”"
              attribution="Dr Julie Roberts, Senior Lecturer, Glasgow Caledonian University"
            />
          </div>
        </Col>

        <Col xs={12} className="p-2 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <Quote
              medium
              content="“Sally creates an environment which is incredibly supportive and full of possibility, all emotions are welcome and encouraged. She is incredibly honest in the most respectful way and uses a multitude of exercises and scientific evidence to enable you to take steps towards the life you want to have.”"
              attribution="Anonymous Client"
            />
          </div>
        </Col>

        <Col xs={12} className="p-2 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <Quote
              medium
              content="“As a result of coaching with Sally I've identified, named and plotted my plans, which has helped me to replace fear with hope. This experience will reverberate for a  long time.”"
              attribution="Anonymous Client"
            />
          </div>
        </Col>

        <Col xs={12} className="p-2 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <Quote
              medium
              content="“Sally is a great professional who has helped me in this journey not by giving all the answers but by encouraging me to find them, so I can keep the joy of discovery to myself.”"
              attribution="Anonymous Client"
            />
          </div>
        </Col>

        <Col xs={12} className="p-2 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <Quote
              medium
              content="“Sally helped me to see that there were ways in which I could break the negativity that was holding me down. I soon realised that I held the key to my future.”"
              attribution="Anonymous Client"
            />
          </div>
        </Col>


        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: colours.satinSheenGold}}>
              Give the gift of transformation
            </h2>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <p>
              To flourish, we need to experience personal growth and we need to be listened to in a way that most family and friends are not qualified to offer.
            </p>
            <p>
              Both of these important needs are met by professional life coaching, and there is a growing appreciation that this is the missing piece of the wellness puzzle in today’s fast-paced world.
            </p>
            <p>
              This Christmas, choose a gift that truly makes a difference. By gifting a 'Revive' coaching package, you're investing in someone's happiness and success.
              It really is the gift that keeps on giving, empowering them to live a more purposeful and fulfilling life.
            </p>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'728px'}}>
            <img
              src={certificate}
              style={{
                width: '100%',
                marginTop: '20px',
                filter: 'drop-shadow(0 0 20px #f7d490)'
              }}
              alt="Gift certificate example"
            />
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <p>
              As a special offer this season Cotswold Coaching Clinic is reducing the price of vouchers by 20%.
              This is only available here until 7pm GMT on Christmas Eve.
            </p>
          </div>
        </Col>

        <Col xs={12} className="my-4 text-center">
          <a href="https://square.link/u/c59ijloB">
            <Button>
              Buy a Revive Coaching Package Gift Voucher<br />
              <span style={{textDecoration:'line-through'}}>&pound;300</span> &pound;240
            </Button>
          </a>
        </Col>

        </Row>

      </Container>
    </React.Fragment>
  )
}

export default GroupCoaching;