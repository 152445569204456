import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from "./components/Header";
import Title from "./components/content/Title";
import AsFeaturedIn from "./components/content/AsFeaturedIn";
import MyMission from "./components/content/MyMission";
import AboutYou from "./components/content/AboutYou";
import HowICanHelpYou from "./components/content/HowICanHelpYou";
import ReviveAndThrive from "./components/content/ReviveAndThrive";
import WhyMe from "./components/content/WhyMe";
import SelectedTestimonials from "./components/content/SelectedTestimonials";
import Fees from "./components/content/Fees";
import CorporateServices from "./components/content/CorporateServices";
import FrequentlyAskedQuestions from "./components/content/FrequentlyAskedQuestions";
import GetInTouch from "./components/content/GetInTouch";
import Footer from "./components/Footer";
import Onboarding from "./components/onboarding/Onboarding";
import WheelOfLife from "./components/onboarding/wheel-of-life/WheelOfLife";
import DISCProfile from "./components/onboarding/disc-profile/DISCProfile";
import Questionnaire from "./components/onboarding/questionnaire/Questionnaire";
import FeedbackForm from "./components/onboarding/feedback/FeedbackForm";
import RevivePackage from "./components/content/RevivePackage";

const Main = () => {
  return (
      <React.Fragment>
          <Header />
          <Title />
          <AsFeaturedIn />
          <MyMission />
          <AboutYou />
          <HowICanHelpYou />
          <ReviveAndThrive />
          <WhyMe />
          <SelectedTestimonials />
          <Fees />
          <CorporateServices />
          <FrequentlyAskedQuestions />
          <GetInTouch />
          <Footer />
      </React.Fragment>
  );
}

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/onboarding/:key?" element={<Onboarding />} />
      <Route path="/wheeloflife" element={<WheelOfLife />} />
      <Route path="/disc" element={<DISCProfile />} />
      <Route path="/questionnaire" element={<Questionnaire />} />
      <Route path="/feedback" element={<FeedbackForm />} />
      <Route path="/christmas-2024" element={<RevivePackage />} />
    </Routes>
  )
}

export default App;
